<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <slot name="icon-title" />
    <path d="M19.5 14L12 6.9L4.5 14L3 12.6L12 4L21 12.6L19.5 14Z" fill="currentColor" />
    <path
      d="M11 6H13V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V6Z"
      fill="currentColor"
      class="asf-back-to-top__vertical-line"
    />
  </svg>
</template>
